import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"
import { Link } from "gatsby"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About Lehigh Safety Shoes</h2>
          <p>Founded in 1922 as the Lehigh Rubber and Leather Company, Lehigh's history is rooted in the Lehigh Valley of Pennsylvania where mines/steel foundries recognized the need for additional foot protection from falling and rolling objects. Through the years, Lehigh continued to lead the safety footwear industry through engineering and technology and by providing safety, comfort, style and competitive pricing. Lehigh was the first to service industry workers with on-site shoe trucks stocked with its own branded product as well as an extensive collection of top-name safety footwear brands to help ensure their protection. Today, Lehigh Safety Shoes continues to serve the American worker and relevant industries by designing, manufacturing and providing top quality protective footwear.</p>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-headers/rocky/factory-sepia.jpg"
            className={brandBodyStyles.halfImage}
            alt="Rocky factory 1933"
            imgStyle={{width: `100%`, height: `100%`}}
            style={{width: `100%`, height: `100%`}}
          />
        </div>
      </div>
      {/* <div className={brandBodyStyles.highlightSection}>
        <div className={brandBodyStyles.imageGrid}>
            <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-outdoor-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky outdoor"
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 10px 10px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-work-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky work"
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 20px 10px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-western-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky western"
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 10px 20px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-psd-commil-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky public service and commercial military"
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 20px 20px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
          </div>
          <div className={brandBodyStyles.highlights}>
            <h3>Highlights</h3>
            <h4>HydroShock:</h4>
            <p>Each steel toe rubber Hydroshock dielectric waterproof work boot is hydroshock tested to 2500 volts DC with less than 5 micro amps of current leakage. In addition, the boot is made with insulating bottoms providing secondary electrical hazard protection in areas dealing with open circuits under dry conditions.</p>
            <h4>Swampers:</h4>
            <p>This foundational brand boot is popular in harsh work environments. The Lehigh &amp; Dri waterproof construction and TPU lower portion keep feet dry while also protecting from leather breakdown from chemical exposure.</p>
          </div>
      </div> */}
      <div className={brandBodyStyles.strategy}>
        <h4>Lehigh Safety Shoes Strategy</h4>
        <p>The Lehigh Safety Shoes goal is to provide quality safety footwear to the industrial workerforce. Through our own quality branded footwear as well as our sister brands Rocky, Georgia Boot, Durango, Muck and others, our selection of styles and protective features is sure to meet any industry need. All Lehigh Safety Shoes branded styles are exclusively sold off of our retail website and through our managed footwear programs Lehigh CustomFit and Shoe Angel.</p>
        <div className={brandBodyStyles.strategyButtons}>
          <Link to="/customer-resources/for-our-consumers/" title="Customer Resources" className="CTAalt">
            Customer Resources
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BrandBody
