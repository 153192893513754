import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BrandHeader from "../../components/brand-headers/lehighSafetyShoesBrandHeader"
import BrandBody from "../../components/brand-bodies/lehighSafetyShoesBrandBody"

const LehighSafetyPage = ({ pageContext, location }) => {
  const pageTitle = "Lehigh Custom Fit"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />
        <BrandHeader />
        <BrandBody />
      </Layout>
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        Founded in 1922 as the Lehigh Rubber and Leather Company, Lehigh's
        history is rooted in the Lehigh Valley of Pennsylvania where mines/steel
        foundries recognized the need for additional foot protection from
        falling and rolling objects. Through the years, Lehigh continued to lead
        the safety footwear industry through engineering and technology and by
        providing safety, comfort, style and competitive pricing. Lehigh was the
        first to service industry workers with on-site shoe trucks stocked with
        its own branded product as well as an extensive collection of top-name
        safety footwear brands to help ensure their protection. Today, Lehigh
        Safety Shoes continues to serve the American worker and relevant
        industries by designing, manufacturing and providing top quality
        protective footwear.
      </p>
    </>
  )
}

export default LehighSafetyPage

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Georgia Boot</title>
    <meta
      name="description"
      content="Founded in 1922 as the Lehigh Rubber and Leather Company, Lehigh's
      history is rooted in the Lehigh Valley of Pennsylvania where mines/steel
      foundries recognized the need for additional foot protection from
      falling and rolling objects. Through the years, Lehigh continued to lead
      the safety footwear industry through engineering and technology and by
      providing safety, comfort, style and competitive pricing. Lehigh was the
      first to service industry workers with on-site shoe trucks stocked with
      its own branded product as well as an extensive collection of top-name
      safety footwear brands to help ensure their protection. Today, Lehigh
      Safety Shoes continues to serve the American worker and relevant
      industries by designing, manufacturing and providing top quality
      protective footwear."
    />
  </>
)
